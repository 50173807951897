<template>
  <div class="ossFileList">
    <div class="reminder">
      如果要删除目录，目录必须为空，不为空的目录不会被删除。
    </div>
    <div class="searchCondition">
      <div class="searchConditionItem">
        oss：
        <a-select
          v-model="ossId"
          placeholder="请选择oss"
          showSearch
          :filterOption="false"
          @search="getOssList"
          @change="getBucketNameList"
        >
          <a-select-option
            v-for="(item, index) in ossList"
            :key="index"
            :value="item.id"
          >
            {{ item.ossName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        bucketName：
        <a-select
          v-model="bucketName"
          placeholder="请选择bucketName"
          showSearch
          @change="bucketNameChange"
          :disabled="!ossId"
        >
          <a-select-option
            v-for="(item, index) in bucketNameList"
            :key="index"
            :value="item"
          >
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem" v-if="bucketName">
        路径：<template v-for="(item, index) in keyPrefixArr">
          <template v-if="index !== keyPrefixArr.length - 1">
            <a-button type="link" @click="skipDirectory(item)">
              {{ item }}
              /
            </a-button>
          </template>
          <template v-else> {{ item }}/ </template>
        </template>
      </div>
    </div>
    <div class="searchButton">
      <a-button type="primary" @click="query" icon="search">查询</a-button>
      <a-button type="primary" @click="addDirectory" icon="plus"
        >新建文件夹</a-button
      >
      <a-button type="primary" @click="uploadFile" icon="upload"
        >上传文件</a-button
      >
    </div>
    <a-table
      size="small"
      class="fileList"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      :loading="loadingTable"
      :rowKey="(record) => record.keyPrefix"
      :row-class-name="getRowClassName"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
      }"
    >
      <span slot="key" slot-scope="text, record">
        <a-button
          type="link"
          style="padding: 0"
          @click="clickName(record)"
          v-if="record.isDirectory"
        >
          {{ text }}
        </a-button>
        <template v-else>{{ text }}</template>
      </span>
      <span slot="isDirectory" slot-scope="text, record">
        <template v-if="record.isDirectory"> 文件夹 </template>
        <template v-if="record.isFile"> 文件 </template>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          @click="downloadFile(record)"
          :disabled="record.isDirectory"
          >下载</a-button
        >
        <a-divider type="vertical" />
        <a-button
          type="link"
          @click="rename(record)"
          :disabled="record.isDirectory"
          >重命名</a-button
        >
      </span>
    </a-table>

    <a-button
      type="danger"
      icon="delete"
      :disabled="selectedRowKeys.length == 0"
      @click="batchDelete"
      >批量删除</a-button
    >
    <a-modal
      title="新建文件夹"
      v-model="addDirectoryShow"
      :maskClosable="false"
      width="500px"
    >
      <a-input
        :addon-before="keyPrefix"
        v-model="directoryName"
        placeholder="请输入文件夹名称"
      ></a-input>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addDirectoryShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addDirectory_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <a-modal
      title="上传文件"
      v-model="uploadFileShow"
      :maskClosable="false"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="上传文件:">
          <a-upload-dragger
            :fileList="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">单击或拖动文件到此区域进行上传</p>
            <p class="ant-upload-hint">只支持单个上传</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="uploadFileShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="uploadFile_submit"
            :loading="loading"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="重命名"
      v-model="renameShow"
      :maskClosable="false"
      width="500px"
    >
      <a-input v-model="newKey" placeholder="请输入文件名称" />
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="renameShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="renameSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import * as api from "../lib/ossFileList.js";
import { ossConfigPage } from "../lib/ossConfigList.js";
export default {
  name: "ossFileList",
  data() {
    return {
      loadingTable: false,
      tableColumns: [
        {
          title: "名称",
          dataIndex: "key",
          scopedSlots: { customRender: "key" },
        },
        {
          title: "类型",
          dataIndex: "isDirectory",
          scopedSlots: { customRender: "isDirectory" },
        },
        {
          title: "文件大小",
          dataIndex: "size",
        },
        {
          title: "最后更新时间",
          dataIndex: "lastModified",
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          width: 120,
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      keyPrefix: "",
      selectedRowKeys: [],
      directoryName: "",
      addDirectoryShow: false,
      uploadFileShow: false,
      loading: false,
      ossId: undefined,
      ossList: [],
      bucketName: undefined,
      bucketNameList: [],
      fileList: [],
      currentDirectory: "",
      renameShow: false,
      oldKey: "",
      newKey: "",
    };
  },
  computed: {
    keyPrefixArr() {
      let arr = this.keyPrefix.split("/").filter((item) => {
        return item !== "";
      });
      arr.unshift("root");
      return arr;
    },
  },
  mounted() {
    this.getOssList();
  },
  methods: {
    getOssList(ossName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        ossName,
      };
      ossConfigPage(data).then((res) => {
        if (res.result == 200) {
          this.ossList = res.data.records;
          if (this.ossList.length > 0) {
            this.ossId = this.ossList[0].id;
            this.getBucketNameList();
          }
        }
      });
    },
    getBucketNameList() {
      api.getBucketNameList({ ossId: this.ossId }).then((res) => {
        if (res.result == 200) {
          this.bucketNameList = res.data;
          this.bucketName = this.bucketNameList[0];
          this.query();
        }
      });
    },
    skipDirectory(item) {
      if (item == "root") {
        this.keyPrefix = "";
      } else {
        this.keyPrefix = this.keyPrefix.split(item + "/")[0] + item + "/";
      }
      this.query();
    },
    bucketNameChange() {
      this.keyPrefix = "";
      this.query();
    },
    query() {
      this.loadingTable = true;
      let data = {
        ossId: this.ossId,
        bucketName: this.bucketName,
        keyPrefix: this.keyPrefix,
      };
      api.getFileList(data).then((res) => {
        if (res.result === 200) {
          res.data.forEach((item) => {
            item.keyPrefix = this.keyPrefix + item.key;
            if (item.key.slice(-1) == "/") {
              item.isDirectory = true;
              item.isFile = false;
            } else {
              item.isFile = true;
              item.isDirectory = false;
            }
            item.size = this.bytesToSize(item.size);
            if (item.lastModified) {
              item.lastModified = this.$common.transformTime(item.lastModified);
            }
            item.keyShow = false;
          });
          this.tableDataSource = res.data.filter((item) => {
            return item.key;
          });
          this.loadingTable = false;
        }
      });
    },
    bytesToSize(size) {
      if (size < 1024) {
        //小于1KB，则转化成B
        size = size.toFixed(2) + " B";
      } else if (size < 1024 * 1024) {
        // 小于1MB，则转化成KB
        size = (size / 1024).toFixed(2) + " KB";
      } else if (size < 1024 * 1024 * 1024) {
        // 小于1GB，则转化成MB
        size = (size / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        // 其他转化成GB
        size = (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }

      // 转成字符串
      let sizeStr = size + "",
        // 获取小数点处的索引
        index = sizeStr.indexOf("."),
        // 获取小数点后两位的值
        dou = sizeStr.substr(index + 1, 2);

      // 判断后两位是否为00，如果是则删除00
      if (dou == "00")
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);

      return size;
    },
    getRowClassName(record, index) {
      return "";
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    rename(val) {
      this.renameShow = true;
      this.oldKey = val.key;
      this.newKey = val.key;
    },
    renameSubmit() {
      let data = {
        ossId: this.ossId,
        bucketName: this.bucketName,
        oldKey: this.oldKey,
        newKey: this.newKey,
      };
      api.rename(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.renameShow = false;
          this.query();
        }
      });
    },
    addDirectory() {
      this.directoryName = "";
      this.addDirectoryShow = true;
    },
    addDirectory_submit() {
      let data = {
        ossId: this.ossId,
        bucketName: this.bucketName,
        dirName: this.keyPrefix + this.directoryName,
      };
      api.createDir(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("创建成功");
          this.addDirectoryShow = false;
          this.query();
        }
      });
    },
    uploadFile() {
      this.fileList.splice(0);
      this.uploadFileShow = true;
    },
    // 上传文件数据处理
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      this.fileList = this.fileList.slice(-1);
      return false;
    },
    uploadFile_submit() {
      if (this.fileList.length == 0) {
        this.$message.warring("请先选择文件");
        return;
      }
      this.loading = true;
      this.$message.loading({ content: "上传中", key: "uploadFile" });
      let data = {
        ossId: this.ossId,
        bucketName: this.bucketName,
        file: this.fileList[0],
        keyPrefix: this.keyPrefix ? this.keyPrefix : "/",
      };
      api
        .uploadFile(data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success({ content: "上传成功", key: "uploadFile" });
            this.uploadFileShow = false;
            this.loading = false;
            this.query();
          }
        })
        .catch(() => {
          this.uploadFileShow = false;
          this.loading = false;
        });
    },
    batchDelete() {
      let data = {
        ossId: this.ossId,
        bucketName: this.bucketName,
        objectKeys: this.selectedRowKeys,
      };
      api.batchDeleteFile(data).then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.selectedRowKeys.splice(0);
          this.query();
        }
      });
    },
    clickName(record) {
      if (record.isDirectory) {
        this.keyPrefix = record.keyPrefix;
        this.query();
      }
    },
    // 点击下载
    downloadFile(record) {
      let data = {
        ossId: this.ossId,
        bucketName: this.bucketName,
        objectKey: record.keyPrefix,
      };
      api.download(data).then((res) => {
        this.blobDownloads(res, record.key);
      });
    },
    blobDownloads(data, fileName) {
      if (!data) {
        return;
      }
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
  },
};
</script>
<style lang="scss" scoped>
.ossFileList {
  &::v-deep .ant-table-row-selected {
    td {
      background-color: #efefef !important;
    }
    &:hover td {
      background-color: #efefef !important;
    }
  }
}
</style>
